import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function RefundPolicyCompo() {
    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '50vh',
            px: 2,
            backgroundColor: '#f9f9f9',
        }}
    >
        <Typography
            variant="h3"
            sx={{
                fontWeight: 'bold',
                color: '#2c2c7e', // Adjust for the blue heading
                textAlign: 'center',
                mb: 2,
            }}
        >
            Refund Policy
        </Typography>
        <Paper
            elevation={3}
            sx={{
                p: 3,
                maxWidth: 600,
                backgroundColor: '#ffffff',
                borderLeft: '5px solid #2c2c7e',
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    color: '#333',
                    fontStyle: 'italic',
                    textAlign: 'justify',
                }}
            >
                "Payment for this business course is non-refundable and non-transferable.
                By enrolling, you acknowledge that all fees are fully earned and
                non-refundable, regardless of completion or attendance."
            </Typography>
        </Paper>
    </Box>
    )
}

export default RefundPolicyCompo
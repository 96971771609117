import React from 'react'
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import Footer from '../components/Footer';
import MediaPartners from '../components/MediaPartner';


function Privacy_Policy() {
    return (
        <>
        <Container maxWidth="lg" sx={{ py: 4 }}>
             <Typography
                        variant="h3"
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c2c7e', // Blue color for the title
                            textAlign: 'center',
                            mb: 4,
                        }}
                    >
                       Privacy Policy
                    </Typography>
            <Typography variant="h4" gutterBottom>
               Overview
            </Typography>
            <Typography variant="body1" paragraph>
                We take your privacy seriously and are committed to protecting your right to privacy as a user of our website. We ensure your information is secure. This privacy policy outlines what information is collected, what we do with it, and what you can do about it.
            </Typography>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Bharti Business Consultancy Information Practices
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="The website content is regularly updated and subject to change." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Bharti Business Consultancy is not liable for damages arising from the use of website information." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Downloadable training content is virus-free, but we cannot accept liability for damages due to virus infection." />
                    </ListItem>
                </List>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Information Collection
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Your information will never be sold, exchanged, or disclosed for marketing purposes." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Opt-in for offers is at your discretion." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Browsing does not require registration, but accessing free tests or forums may require voluntary data submission." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Payments are processed securely via CC Avenue, PayPal, or Google Checkout, and credit card information is not stored." />
                    </ListItem>
                </List>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Cookie Information
                </Typography>
                <Typography variant="body1" paragraph>
                    Cookies enhance the website experience but do not store sensitive information or identify users. Cookies may store your name and email temporarily but do not store credit card details.
                </Typography>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    External Links
                </Typography>
                <Typography variant="body1" paragraph>
                    Bharti Business Consultancy is not responsible for the privacy practices of third-party websites linked from our site.
                </Typography>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Personal Information and Video Capture
                </Typography>
                <Typography variant="body1" paragraph>
                    Images from Bharti Business Consultancy events may feature participants and staff. Write to{' '}
                    <a href="mailto:Support@bhartibusinessconsultancy.com">Support@bhartibusinessconsultancy.com</a> to opt out of photo or video use.
                </Typography>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Policy Updates
                </Typography>
                <Typography variant="body1" paragraph>
                    Privacy policies may change, with registered users notified via email.
                </Typography>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Deleting Personal Information
                </Typography>
                <Typography variant="body1" paragraph>
                    Email{' '}
                    <a href="mailto:Support@bhartibusinessconsultancy.com">Support@bhartibusinessconsultancy.com</a> to request account deletion.
                </Typography>
            </Box>
        </Container>
        <MediaPartners/>
        <Footer/>
        </>
    )
}

export default Privacy_Policy
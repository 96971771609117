import React from 'react'
import MediaPartners from '../components/MediaPartner'
import Footer from '../components/Footer'
import { Box, Typography, Paper, Container } from '@mui/material';

function TermsandCondition() {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: '#f9f9f9',
                    minHeight: '100vh',
                    py: 5,
                }}
            >
                <Container maxWidth="md">
                    <Typography
                        variant="h3"
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c2c7e', // Blue color for the title
                            textAlign: 'center',
                            mb: 4,
                        }}
                    >
                        Terms & Conditions
                    </Typography>

                    {/* Section 1 */}
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 4,
                            p: 3,
                            backgroundColor: '#ffffff',
                            borderLeft: '5px solid #2c2c7e',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            1. Introduction
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', textAlign: 'justify', lineHeight: 1.6 }}>
                            These Terms and Conditions, along with privacy policy or other terms
                            (“Terms”) constitute a binding agreement by and between RAVINDRA
                            BHARTI BUIZCORP LIMITED, (“Website Owner” or “we” or “us” or “our”)
                            and you (“you” or “your”) and relate to your use of our website,
                            goods (as applicable) or services (as applicable) (collectively,
                            “Services”).
                            <br />
                            <br />
                            By using our website and availing the Services, you agree that you
                            have read and accepted these Terms (including the Privacy Policy).
                            We reserve the right to modify these Terms at any time and without
                            assigning any reason. It is your responsibility to periodically
                            review these Terms to stay informed of updates.
                        </Typography>
                    </Paper>

                    {/* Section 2 */}
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 4,
                            p: 3,
                            backgroundColor: '#ffffff',
                            borderLeft: '5px solid #2c2c7e',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            2. Use of Services
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', textAlign: 'justify', lineHeight: 1.6, mb: 2 }}>
                            To access and use the Services, you agree to provide true, accurate,
                            and complete information to us during and after registration, and
                            you shall be responsible for all acts done through the use of your
                            registered account.
                        </Typography>
                        <Typography component="ul" sx={{ pl: 3, color: '#555', lineHeight: 1.6, mb: 2 }}>
                            <li>
                                Neither we nor any third parties provide any warranty or guarantee
                                as to the accuracy, timeliness, performance, completeness, or
                                suitability of the information and materials offered on this
                                website or through the Services, for any specific purpose.
                            </li>
                            <li>
                                You acknowledge that such information and materials may contain
                                inaccuracies or errors and we expressly exclude liability for any
                                such inaccuracies or errors to the fullest extent permitted by
                                law.
                            </li>
                            <li>
                                Your use of our Services and the website is solely at your own
                                risk and discretion. You are required to independently assess and
                                ensure that the Services meet your requirements.
                            </li>
                        </Typography>
                    </Paper>

                    {/* Section 3 */}
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 4,
                            p: 3,
                            backgroundColor: '#ffffff',
                            borderLeft: '5px solid #2c2c7e',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            3. Intellectual Property
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', textAlign: 'justify', lineHeight: 1.6 }}>
                            The contents of the Website and the Services are proprietary to Us
                            and you will not have any authority to claim any intellectual
                            property rights, title, or interest in its contents.
                        </Typography>
                    </Paper>

                    {/* Section 4 */}
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 4,
                            p: 3,
                            backgroundColor: '#ffffff',
                            borderLeft: '5px solid #2c2c7e',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            4. Liability and Indemnity
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', textAlign: 'justify', lineHeight: 1.6 }}>
                            You acknowledge that unauthorized use of the Website or the Services may lead to action against you as per these Terms or applicable laws.
                            You agree to pay us the charges associated with availing the Services.
                            You agree not to use the website and/or Services for any purpose that is unlawful, illegal or forbidden by these Terms, or Indian or local laws that might apply to you.
                        </Typography>
                    </Paper>

                    {/* Section 5 */}
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 4,
                            p: 3,
                            backgroundColor: '#ffffff',
                            borderLeft: '5px solid #2c2c7e',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            5. Links to Third Party Websites
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', textAlign: 'justify', lineHeight: 1.6 }}>
                            You agree and acknowledge that website and the Services may contain links to other third party websites. On accessing these links, you will be governed by the terms of use, privacy policy and such other policies of such third party websites.
                        </Typography>
                    </Paper>
                    {/* Section 6 */}
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 4,
                            p: 3,
                            backgroundColor: '#ffffff',
                            borderLeft: '5px solid #2c2c7e',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                            6. Governing Law and Jurisdiction
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', textAlign: 'justify', lineHeight: 1.6 }}>
                            These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and construed in accordance with the laws of India.

                            All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Pune, Maharashtra.

                            All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.
                        </Typography>
                    </Paper>
                </Container>
            </Box>
            <MediaPartners />
            <Footer />
        </>
    )
}

export default TermsandCondition
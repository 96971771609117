
import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  CircularProgress,
} from "@mui/material";

const SuccessSecretForm = () => {
  const [formData, setFormData] = useState({
    Name_First: "",
    Name_Last: "",
    PhoneNumber_countrycodeval: "+91", 
    PhoneNumber_countrycode: "",
    Email: "",
    SingleLine: "",
    Dropdown: "Marathi", 
  });
  const [loading, setLoading] = useState(false); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true); // Start loader
    // Let the form proceed with default submission
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "0 auto",
        padding: "20px",
        backgroundColor: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        Success Secret of Business & Life
      </h2>
      <form
        onSubmit={handleSubmit}
        action="https://forms.zohopublic.in/ravindrabhartieducationinstitu/form/SuccessSecretofBusinessLife/formperma/I4jif4Z2T8ItaPA8uR7asrcMmpqwhf0Q_6O7reZh25s/htmlRecords/submit"
        name="form"
        method="POST"
      >
        <Grid container spacing={1}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="Name_First"
              value={formData.Name_First}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="Name_Last"
              value={formData.Name_Last}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Phone Code */}
         
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
        <InputLabel id="country-code-label"></InputLabel>
        <Select
          labelId="country-code-label"
          name="PhoneNumber_countrycodeval"
          value={formData.PhoneNumber_countrycodeval}
          onChange={handleChange}
          required
        >
          {/* Dropdown options for country codes */}
          <MenuItem value="+91">+91 (India)</MenuItem> 
        </Select>
      </FormControl>
    </Grid>

          {/* Phone Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="PhoneNumber_countrycode"
              value={formData.PhoneNumber_countrycode}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="Email"
              type="email"
              value={formData.Email}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* City */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="City"
              name="SingleLine"
              value={formData.SingleLine}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Preferred Language */}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Preferred Language</InputLabel>
              <Select
                name="Dropdown"
                value={formData.Dropdown}
                onChange={handleChange}
                label="Preferred Language"
              >
                <MenuItem value="Marathi">Marathi</MenuItem>
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ padding: "12px", fontSize: "16px" }}
              disabled={loading} // Disable button during loading
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SuccessSecretForm;


import React from "react";
import {
    Container,
    Box,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    Button,
    Grid,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const SingleCard = () => {
    const detail = {
        planTitle: "Residential & Training Program",
        originalPrice: 14990,
        discountPrice: 9990,
        listItems: [
            { text: "Registration Kit", available: true },
            {
                text: "Access to Success Secret of Business & Life Pune Event (21st & 22nd Dec)",
                available: true,
            },
            { text: "Access To Exclusive Community", available: true },
            //   { text: "Seating on First Row Premium-Sofa", available: true },
            { text: "Adventure and Amusement Park Riding" },
            { text: "Morning High Tea", available: true },
            { text: "Lunch", available: true },
            { text: "Evening High Tea", available: true },
            { text: "Dinner", available: true },
            { text: "Resort Stay (One Night)", available: true },
            { text: "Selfie & Autograph with Mr. Ravindra Bharti", available: true },
            {
                text: `Special Session With Mr. Ravindra Bharti - Financial Planning & Money Management session (Worth Rs.60,000/-)`,
                available: true,
            },
            {
                text: `Billionaire Blue Print - Share Market Book by Mr.Ravindra Bharti Sir (Worth Rs.999/-)`,
                available: true,
            },
        ],
    };

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Box sx={{ textAlign: "center", mb: 4 }}>
                <Typography variant="h3" sx={{ fontWeight: 600, color: "#7065f0" }}>
                    Book Your Seat
                </Typography>
            </Box>

            <Card
                sx={{
                    boxShadow: 3,
                    borderRadius: 3,
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                        transform: "scale(1.02)",
                    },
                }}
            >
                <Grid container>
                    {/* Left Section: Pricing and VIP Title */}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            bgcolor: 'transparent', // Set to transparent to use gradient
                            background: 'linear-gradient(90deg, purple, #7065F0)',
                            color: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 4,
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={600}
                            sx={{ textAlign: "center", mb: 2 }}
                        >
                            {detail.planTitle}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                textDecoration: "line-through",
                                fontSize: "1.5rem",
                                color: "rgba(255,255,255,0.8)",
                            }}
                        >
                            ₹{detail.originalPrice}
                        </Typography>
                        <Typography
                            variant="h3"
                            fontWeight={600}
                            sx={{ fontSize: "2.5rem", mt: 1 }}
                        >
                            ₹{detail.discountPrice}
                        </Typography>
                    </Grid>

                    {/* Right Section: Content and Buttons */}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <CardContent id="pricingCard">
                            {/* Feature List */}
                            <List>
                                {detail.listItems.map((listData, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: 500,
                                            margin: "0.5rem 0",
                                            display: "flex",
                                            gap: 1,
                                            alignItems: "center",
                                        }}
                                    >
                                        <CheckCircleOutlineIcon sx={{ color: "green" }} />
                                        <span>{listData.text}</span>
                                    </ListItem>
                                ))}
                            </List>

                            {/* Action Buttons */}
                            <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        backgroundColor: "#7065f0",
                                        color: "white",
                                        fontWeight: "bold",
                                        "&:hover": {
                                            backgroundColor: "#5946d2",
                                        },
                                    }}
                                    onClick={() => {
                                        const isSmallScreen = window.innerWidth < 768;
                                        const scrollToPosition = isSmallScreen ? 200 : 0;
                                        window.scrollTo(0, scrollToPosition);
                                    }}
                                >
                                    Buy Now
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        width: "100%",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                        const isSmallScreen = window.innerWidth < 768;
                                        const scrollToPosition = isSmallScreen ? 200 : 0;
                                        window.scrollTo(0, scrollToPosition);
                                    }}
                                >
                                    Buy 2 Seats @ ₹{(detail.discountPrice * 2) - 1500}
                                </Button>
                            </Box>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
};

export default SingleCard;

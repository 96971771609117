import React from 'react';
import { Box, Typography, useMediaQuery, useTheme, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import img1 from '../assets/bigfm.jpg';
import img2 from '../assets/sakal.jpg';
import img3 from '../assets/saam.jpg';
import img4 from '../assets/pudhari.jpg';
import img5 from '../assets/mirchi.jpg';
import img6 from '../assets/BSM-WEBSITE-TEDX-LOGO (1).jpg';

const Root = styled(Box)(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(6, 0),
    backgroundColor: '#1a237e',
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10, 0),
    },
}));

const Gradient = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, #1a237e 0%, #7c4dff 100%)',
    zIndex: 0,
});

const Container = styled(Box)({
    position: 'relative',
    zIndex: 1,
});

const ScrollingWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    animation: 'scroll 20s linear infinite',
    width: '100%',
    gap: theme.spacing(4),
    '@keyframes scroll': {
        '0%': { transform: 'translateX(100%)' },
        '100%': { transform: 'translateX(-100%)' },
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
}));

const partners = [
    { name: 'TedX', logo: img6, alt: 'TedX' },
    { name: 'BIG FM', logo: img1, alt: 'BIG FM logo' },
    { name: 'Radio Mirchi', logo: img5, alt: 'Radio Mirchi logo' },
    { name: 'Saam TV', logo: img3, alt: 'Saam TV logo' },
    { name: 'Sakal', logo: img2, alt: 'Sakal logo' },
    { name: 'Pudhari', logo: img4, alt: 'Pudhari logo' },
];

export default function MediaPartners() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Root>
            <Gradient />
            <Container>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: { xs: '2.5rem', md: '3.75rem' },
                        marginBottom: theme.spacing(6),
                    }}
                >
                    Featured At
                </Typography>
                <Box sx={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
                    <ScrollingWrapper>
                        {partners.map((partner, index) => (
                            <StyledPaper key={index} elevation={3}>
                                <img
                                    src={partner.logo}
                                    alt={partner.alt}
                                    width={isMobile ? 100 : 150}
                                    height={isMobile ? 75 : 75}
                                    style={{ objectFit: 'contain' }}
                                />
                            </StyledPaper>
                        ))}
                        {/* Repeat logos for seamless scrolling */}
                        {partners.map((partner, index) => (
                            <StyledPaper key={`${index}-duplicate`} elevation={3}>
                                <img
                                    src={partner.logo}
                                    alt={partner.alt}
                                    width={isMobile ? 100 : 150}
                                    height={isMobile ? 75 : 75}
                                    style={{ objectFit: 'contain' }}
                                />
                            </StyledPaper>
                        ))}
                    </ScrollingWrapper>
                </Box>
            </Container>
        </Root>
    );
}

import React from 'react'
import MediaPartners from '../components/MediaPartner'
import Footer from '../components/Footer'
import RefundPolicyCompo from '../components/RefundPolicy'

function Refund_Policy() {
    return (
        <>
            <RefundPolicyCompo />
            <MediaPartners />
            <Footer />
        </>
    )
}

export default Refund_Policy